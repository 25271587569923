import React from 'react';
import { Link } from 'react-router-dom';

function AlertDialog({ message, linkTo, linkText, type }) {
  const classType = type ? `alert-${type}` : 'alert-primary';

  return (
    <div
      className={`alert ${classType} alert-dismissible fade show mt-2 shadow-sm`}
      id="error-alert"
      role="alert"
    >
      {message}
      {linkTo && <Link to={linkTo}> {linkText}</Link>}
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
}

export default AlertDialog;
