import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import Footer from '../common/Footer';
import Topbar from '../common/Topbar';
import Sidebar from '../common/Sidebar';
import Spinner from '../common/Spinner';
import LogoutModal from '../common/LogoutModal';
import ReceiveGoodsModal from './ReceiveGoodsModal';
import { getPODestinations } from '../../actions/configActions';
import { getPoToBeReceived, setGRLoading } from '../../actions/grActions';
import Alerts from '../common/Alerts';

function ViewPurchaseOrdersSecurity({
  error,
  client,
  receive,
  loading,
  destinations,
  setGRLoading,
  getPODestinations,
  getPoToBeReceived
}) {
  useEffect(() => {
    destinations.length === 0 && getPODestinations();

    setGRLoading(false);

    return () => {
      window.$('#receiveGoodsModal').modal('hide');
    };
    // eslint-disable-next-line
  }, []);

  const [selected, setSelected] = useState('');
  const [filter, setFilter] = useState({ destination: '', date: '' });

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Receive Items" />
            <div className="container-fluid mb-4">
              <Alerts />

              <select
                className="btn btn-primary shadow-sm float-left mb-2"
                value={filter.destination}
                onChange={(e) => {
                  if (e.target.value !== '') {
                    getPoToBeReceived(e.target.value, filter.date);
                    setFilter({ ...filter, destination: e.target.value });
                  }
                }}
              >
                <option defaultValue value="">
                  Filter by destination
                </option>
                {destinations.length === 0 && (
                  <option value="PO">{client}</option>
                )}
                {destinations.map((destination) => (
                  <option
                    value={destination.configValue}
                    key={destination.configValue}
                  >
                    {destination.configName}
                  </option>
                ))}
              </select>

              <label htmlFor="selectDate">
                <i
                  className="fas fa-calendar ml-4 mt-1 text-primary font-weight-bold"
                  style={{ fontSize: '30px' }}
                />
              </label>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'left',
                  marginTop: '-40px'
                }}
              >
                <ReactDatePicker
                  className="form-control shadow-sm dis-none"
                  id="selectDate"
                  onChange={(date) => {
                    setFilter({
                      ...filter,
                      date: `${date.getFullYear()}-${date.getMonth() + 1}`
                    });

                    if (filter.destination !== '') {
                      getPoToBeReceived(
                        filter.destination,
                        `${date.getFullYear()}-${date.getMonth() + 1}`
                      );
                    }
                  }}
                  maxDate={Date.now()}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                />

                <button
                  className="btn btn-danger btn-sm shadow-sm"
                  style={{ marginLeft: '5rem' }}
                  onClick={() => {
                    receive.length !== 0 && getPoToBeReceived();
                    setFilter('');
                    setFilter({ destination: '', date: '' });
                  }}
                >
                  Clear
                </button>
              </div>

              {filter.destination && (
                <h4
                  className="text-center font-weight-bold text-black"
                  style={{ marginTop: '-25px' }}
                >
                  {filter.destination}{' '}
                  {filter.date &&
                    moment(filter.date, 'YYYY/MM').format('MMM YYYY')}
                </h4>
              )}

              <div className="clearfix" />

              {filter.destination === '' && (
                <div className="alert alert-primary mt-2" role="alert">
                  Please select a destination...
                </div>
              )}

              {filter.destination !== '' &&
                receive.length === 0 &&
                !loading && (
                  <div className="alert alert-warning" role="alert">
                    No orders for this destination
                  </div>
                )}

              {loading && <Spinner classStyle height="50px" width="50px" />}

              {/* {loading && <Spinner classStyle={'text-center'} />} */}

              {error && <p className="text-danger font-weight-bold">{error}</p>}

              {!loading &&
                receive.map((po) => (
                  <div
                    className="card text-black shadow-sm mt-1 mb-2 expand-on-hover"
                    data-toggle="modal"
                    data-target="#receiveGoodsModal"
                    style={{ cursor: 'pointer' }}
                    key={po.serialNumber}
                    onClick={() => setSelected(po.poNumber)}
                  >
                    <div className="card-body small-card">
                      <div className="float-left mt-1">
                        <h4 className="align-middle">
                          # {po.poNumber}
                          <span
                            style={{ fontSize: '11px' }}
                            className="ml-4 mr-4 badge badge-pill badge-info shadow-sm"
                          >
                            {moment(po.approvedAt).format('DD MMMM YYYY')}
                          </span>
                          {po.requisition.map((creator, index) => (
                            <span
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              style={{ fontSize: '11px' }}
                              className="badge badge-pill badge-light shadow-sm mr-2"
                            >
                              {creator}
                            </span>
                          ))}
                        </h4>
                      </div>
                      <button className="btn btn-info btn-circle btn float-right">
                        <i className="fas fa-check-circle" />
                      </button>
                    </div>
                  </div>
                ))}
            </div>

            <ReceiveGoodsModal selected={selected} filter={filter} />
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  role: state.user.role,
  client: state.user.client.name,
  error: state.goodsreceived.error,
  receive: state.goodsreceived.receive,
  loading: state.goodsreceived.loading,
  destinations: state.config.destinations
});

export default connect(mapStateToProps, {
  getPoToBeReceived,
  getPODestinations,
  setGRLoading
})(ViewPurchaseOrdersSecurity);
