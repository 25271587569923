import React from 'react';
import { connect } from 'react-redux';
import AlertDialog from './AlertDialog';

function Alerts({ role }) {
  return (
    <div>
      {process.env.REACT_APP_PAYMENT_ALERT === 'true' && (
        <AlertDialog
          type="warning"
          message="Payment Pending: Please settle pending overdue payments within
             30 days to avoid service interruption - This is an automated message."
        />
      )}

      {['production', 'inventory'].includes(role) && (
        <AlertDialog
          message="Mark items as not required by clicking on my items from the requisition
                  option. Check This frequently to avoid duplicate/overlap items."
          linkTo={`/${role}/requisition/items`}
          linkText="Click here to check items"
        />
      )}

      {role === 'purchaseorder' && (
        <AlertDialog
          message="Force Close PO's from Menu to remove existing items & clear
                off items list."
          linkTo="/purchaseorder/security"
          linkText="Click here to force close items"
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  role: state.user.role
});

export default connect(mapStateToProps, {})(Alerts);
