import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import Topbar from '../common/Topbar';
import Footer from '../common/Footer';
import Sidebar from '../common/Sidebar';
import LogoutModal from '../common/LogoutModal';
import Content from '../common/Dashboard/Content';
import { getDashboard } from '../../actions/configActions';
import Alerts from '../common/Alerts';

function InventorySectionDashboard({ dashboardData, getDashboard }) {
  useEffect(() => {
    getDashboard();
    // eslint-disable-next-line
  }, []);

  const dashboard = [
    {
      title: 'Total Requisitions',
      data: dashboardData.activeRequisitions,
      to: '/inventory/requisition',
      icon: 'calendar',
      style: 'primary'
    },
    {
      title: 'My Requisition Items',
      data: '',
      to: '/inventory/requisition/items',
      icon: 'shopping-cart',
      style: 'dark'
    },
    {
      title: 'Items Pending for Purchase',
      subHeading: 'Requisition approved, PO not created',
      data: dashboardData.pendingForPurchase,
      to: '/inventory/purchaseorder/pending',
      icon: 'shopping-cart',
      style: 'warning'
    },
    {
      title: 'Items to be Received',
      subHeading: 'PO approved, Items not received',
      data: dashboardData.itemsToBeReceived,
      to: '/inventory/purchaseorder/track?filter=approved',
      icon: 'truck',
      style: 'info'
    },
    {
      title: 'Pending GR',
      data: dashboardData.receivedAtSecurity,
      to: '/inventory/goodsreceived',
      icon: 'clipboard',
      style: 'success'
    },
    {
      title: 'Items Below Minimum Stock',
      data: dashboardData.belowMinimumStock || '-',
      to: '/inventory/inventory?filter=below_minimum',
      icon: 'exclamation-circle',
      style: 'danger'
    }
  ];

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar title="Dashboard" />

            <div className="container-fluid">
              <Alerts />

              <Content dashboard={dashboard} />

              <div className="row" />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  dashboardData: state.user.dashboard
});

export default connect(mapStateToProps, { getDashboard })(
  InventorySectionDashboard
);
